import loadable from '@loadable/component'
import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ModalCall = loadable(() => import('../ModalCall'))

const AboutPageTemplate = (props) => {
  const { title, content, contentComponent } = props
  const PageContent = contentComponent || Content

  return (
    <>
      <section className='hero is-primary is-bold' style={{ minHeight: '70%', backgroundImage: 'url(/img/o-nas.svg)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'top center', backgroundAttachment: `fixed` }}>
        <div className='hero-body'>
          <div className='container section'>
            <div className='columns section'>
              <div>
                <h1 className='title' style={{ fontSize: '50px' }}>
                  {title}
                </h1>
                <h2 className='subtitle'>
                Find out who we are and what makes us different.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='is-primary is-bold' style={{ marginTop: '-7px', backgroundImage: 'url(/img/tlo-o-nas.jpg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'left', backgroundAttachment: `fixed`, }}>
        <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{ color: '#23C495' }}><b>{title}</b></span>
        </nav>
        <div className='hero-body' style={{ marginTop: '-50px' }}>
          <div className='container'>
            <div className='columns' style={{ textAlign: 'center' }}>

              <div className='column' style={{ marginLeft: '5%', marginRight: '10%' }}>

                <br />
                <h3 style={{ textAlign: 'left' }}><strong style={{ color: 'white', fontSize: '20px' }}>Advertising agency <br /><b style={{ color: '#23C495', fontSize: '25px' }}> <Link className="inline-link" to="/">Art Open</Link></b></strong></h3>
                <br />
                <p style={{ textAlign: 'left', color: 'white' }}>Our <Link className="inline-link" to="#zespol">team</Link> is full of people with passion, creativity and commitment to each project they participate in. For nearly a decade, together with you - our clients - we have been implementing various projects in the field of <Link className="inline-link" to="/offer/branding/">branding</Link>, designing <Link className="inline-link" to="/offer/book-calendars/">book calendars</Link> and <Link className="inline-link" to="/offer/company-calendars/">advertising calendars</Link>, <Link className="inline-link" to="/offer/outdoor-advertising/">outdoor advertising</Link>, <Link className="inline-link" to="/offer/graphic-design/">graphic design</Link>, <Link className="inline-link" to="/offer/web-pages/">programming websites and online stores</Link>, <Link className="inline-link" to="/offer/vr/">applications including VR and AR</Link>, as well as <Link className="inline-link" to="/offer/photography/">photo shoots</Link> and <Link className="inline-link" to="/offer/filming/">movies</Link> of different kinds.</p>

                <div className='columns' style={{ marginLeft: '2%', marginTop: '50px' }}>
                  <div>
                    <a className='button-white' href="#zespol">
                      <img width="12px" style={{ verticalAlign: 'middle', marginRight: '15px' }} alt="our team" src='/img/team.svg' />
                      our team
                    </a>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div>
                    <a className='button-white' href="#wyroznia-nas">
                      <img width="12px" style={{ verticalAlign: 'middle', marginRight: '15px' }} alt="what sets us apart?" src='/img/seedling.svg' />
                      what sets us apart?
                    </a>
                  </div>
                </div>


              </div>

              <div className='column' style={{ marginLeft: '5%', marginRight: '5%' }}>
                <br />
                <br />
                <p style={{ textAlign: 'left', color: 'white' }}>Each of your new projects gives us a new experience and allows us to meet new challenges. We are grateful to our clients for putting their trust in us and for including us in the group of their associates. It gives us a sense of satisfaction in working to achieve common goals. </p>
                <br />
                <p style={{ textAlign: 'left', color: 'white' }}>One of them, extremely important, are activities supporting  <Link className="inline-link" to="/blog/why-to-be-eco-friendly/">the protection of our natural environment</Link> by offering <Link className="inline-link" to="/offer/ecology/">ecological and biodegradable products, such as stone or grass paper</Link>. We want to make our small contribution to the global pro-eco movement. We are looking for modern, innovative solutions limiting the consumption of natural resources. In our offer you will find modern <Link className="inline-link" to="/offer/gadgets/">advertising gadgets</Link> made of materials such as bamboo or organic cotton. </p>
              </div>

            </div>
            <br />
          </div>
        </div>
      </section>


      <section className='section' style={{ backgroundColor: '#f5f5f5' }}>
        <div className='container' style={{ textAlign: 'center' }}>
          <h3 className='title stays-dark'> How do we work? </h3>
          <h4 className='subtitle stays-dark'> Conscientious, timely and committed. </h4>
          <br /><br /><br />
          <div className='columns'>

            <div className='column'>
              <img src="/img/i-pomysl.svg" alt="idea" width="50px" />
              <br /><br />
              <h3 className='is-size-4 stays-dark'> Idea </h3>
              <p style={{ color: '#000000' }}>We meet to learn about our clients' needs and discuss project details. </p>

            </div>

            <div className='column'>
              <img src="/img/i-analiza.svg" alt="analysis" width="50px" />
              <br /><br />
              <h3 className='is-size-4 stays-dark'> Analysis </h3>
              <p style={{ color: '#000000' }}>We analyze the collected information and look for the most optimal solutions for a given project.</p>

            </div>

            <div className='column'>
              <img src="/img/i-oferta.svg" alt="offer" width="50px" />
              <br /><br />
              <h3 className='is-size-4 stays-dark'> Offer </h3>
              <p style={{ color: '#000000' }}>We prepare a detailed quote for the service, which is finally finalized by sending an order.</p>
            </div>

            <div className='column'>
              <img src="/img/i-realizacja.svg" alt="implementation" width="50px" />
              <br /><br />
              <h3 className='is-size-4 stays-dark'> Implementation </h3>
              <p style={{ color: '#000000' }}>We carry out the project according to a predetermined scheme, consulting the next steps with the client.</p>

            </div>

          </div>
        </div>
      </section>


      <section id="zespol" className='section section--gradient'>
        <div className='container' style={{ paddingTop: '50px' }}>
          <div className='columns'>
            <h4 className='has-text-weight-semibold is-size-3 column'>Get to know our <b style={{ color: '#00d1b2' }}>team</b>:</h4>

            <p className='column'>Out-of-the-box, full of innovativity, and highly aesthetic: those  are the advantages of <Link className="inline-link" to="/portfolio/">our projects</Link>. We have lots of experience, creativity and complete commitment to each project. We love <Link className="inline-link" to="/offer/">what we do and treat work as a personal challenge</Link>.</p>
          </div>
        </div>
        <div className='columns'>
          <div className='column'>
            <div className='columns' style={{ marginTop: '70px', marginLeft: '15%', marginRight: '15%', marginBottom: '50px', textAlign: 'center' }}>

              <div className='column'>
                <img className='oimg' alt="Jacek Wiśniewski" src='/img/jw.jpg' width='50%' />
                <p><strong> Jacek Wiśniewski </strong></p>
                <small style={{ fontSize: '10px' }}> President of the Board </small>
                <br />
                <a className='link-green' style={{ fontSize: '12px' }} href='mailto:jwisniewski@artopen.pl' target="_blank" >
                  <img width="12px" style={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '15px' }} alt="email" src='/img/envelope-alt.svg' />
                  jwisniewski@artopen.pl
                </a>
                <br /><br />
              </div>

              <div className='column'>
                <img className='oimg' alt="Beata Wiśniewska" src='/img/b-w-2.jpg' width='50%' />
                <p><strong> Beata Wiśniewska </strong></p>
                <small style={{ fontSize: '10px' }}> Member of the Board </small>
                <br />
                <a className='link-green' style={{ fontSize: '12px' }} href='mailto:bwisniewska@artopen.pl' target="_blank" >
                  <img width="12px" style={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '15px' }} alt="email" src='/img/envelope-alt.svg' />
                  bwisniewska@artopen.pl
                </a>
                <br />
              </div>

            </div>

            <div className='columns' style={{ marginLeft: '15%', marginRight: '15%', marginBottom: '50px', textAlign: 'center' }}>

              <div className='column'>
                <img alt="Monika Mazur" className='oimg' src='/img/mm.jpg' width='50%' />
                <p><strong> Monika Mazur </strong></p>
                <small style={{fontSize:'10px'}}> Customer Service Manager </small>
                <br />
                <a className='link-green' style={{fontSize:'12px'}} href='mailto:biuro@artopen.pl' target="_blank" >
                <img width="12px"  style={{verticalAlign:'middle',marginLeft:'5px', marginRight:'15px'}}   alt="email" src='/img/envelope-alt.svg'/>
                biuro@artopen.pl
                </a>
                <br />
              </div>

              <div className='column'>
                <img alt="Patryk Łagódka" className='oimg' src='/img/patryk.jpg' width='50%' />
                <p><strong> Patryk Łagódka </strong></p>
                <small style={{ fontSize: '10px' }}> Graphics Department Manager </small>
                <br />
                <a className='link-green' style={{ fontSize: '12px' }} href='mailto:plagodka@artopen.pl' target="_blank" >
                  <img width="12px" style={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '15px' }} alt="email" src='/img/envelope-alt.svg' />
                  plagodka@artopen.pl
                </a>
                <br />
              </div>

            </div>

            <div className='columns' style={{ marginLeft: '15%', marginRight: '15%', marginBottom: '50px', textAlign: 'center' }}>


              <div className='column'>
                <img alt="Dariusz Kawalec" className='oimg' src='/img/darek.jpg' width='50%' />
                <p><strong> Dariusz Kawalec </strong></p>
                <small style={{ fontSize: '10px' }}> Graphic Designer </small>
                <br />
                <a className='link-green' style={{ fontSize: '12px' }} href='mailto:kontakt@artopen.pl' target="_blank" >
                  <img width="12px" style={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '15px' }} alt="email" src='/img/envelope-alt.svg' />
                  kontakt@artopen.pl
                </a>
                <br /><br />
              </div>

              <div className='column'>
                <img alt="Jerzy Lazaridis" className='oimg' src='/img/jerzy-new.jpg' width='50%' />
                <p><strong> Jerzy Lazaridis </strong></p>
                <small style={{ fontSize: '10px' }}> Web Developer </small>
                <br />
                <a className='link-green' style={{ fontSize: '12px' }} href='mailto:jerzy@artopen.pl' target="_blank" >
                  <img width="12px" style={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '15px' }} alt="email" src='/img/envelope-alt.svg' />
                  jerzy@artopen.pl
                </a>
                <br />
              </div>
            </div>

            {/* Nowe */}

              <div className='columns' style={{marginLeft:'15%',marginRight:'15%',marginBottom:'50px', textAlign:'center'}}>

                <div className='column'>
                  <img alt="Adrianna Kołata" className='oimg' src='/img/ada.jpg' width='50%' />
                  <p><strong> Adrianna Kołata </strong></p>
                  <small style={{fontSize:'10px'}}> Customer Service Specialist </small>
                  <br />
                  <a className='link-green' style={{fontSize:'12px'}} href='mailto:kontakt@artopen.pl' target="_blank" >
                  <img width="12px"  style={{verticalAlign:'middle',marginLeft:'5px', marginRight:'15px'}}   alt="email" src='/img/envelope-alt.svg'/>
                  bok@artopen.pl
                  </a>
                  <br />
                </div>

                <div className='column'>
                  <img alt="Małgorzata Najgebaur" className='oimg' src='/img/gosia.jpg' width='50%' />
                  <p><strong> Małgorzata Najgebaur</strong></p>
                  <small style={{fontSize:'10px'}}> Sales Representative </small>
                  <br />
                  <a className='link-green' style={{fontSize:'12px'}} href='mailto:reklama@artopen.pl' target="_blank" >
                  <img width="12px"  style={{verticalAlign:'middle',marginLeft:'5px', marginRight:'15px'}}   alt="email" src='/img/envelope-alt.svg'/>
                  reklama@artopen.pl
                  </a>
                  <br />

                  <br />
                </div>

              </div>

              <div className='columns' style={{marginLeft:'15%',marginRight:'15%',marginBottom:'50px', textAlign:'center'}}>

                <div className='column'>
                  <img alt="Arkadiusz Grzelak" className='oimg' src='/img/arek.jpg' width='50%' />
                  <p><strong> Arkadiusz Grzelak </strong></p>
                  <small style={{fontSize:'10px'}}> Customer Service Specialist </small>
                  <br />
                  <a className='link-green' style={{fontSize:'12px'}} href='mailto:office@artopen.pl' target="_blank" >
                  <img width="12px"  style={{verticalAlign:'middle',marginLeft:'5px', marginRight:'15px'}}   alt="email" src='/img/envelope-alt.svg'/>
                  office@artopen.pl
                  </a>
                  <br />
                </div>

                <div className='column'>
                </div>

              </div>





          </div>
          <div className='column' style={{ display: 'grid', alignContent: 'center' }}>
            {/* MIEJSCE NA GRAFIKE - PIONOWA ILUSTRACJA */}
            <img alt="ecological advertising agency Art Open Wroclaw" src="/img/about-us.svg" loading="lazy" width="100%" />
          </div>

        </div>



      </section>


      <section id='wyroznia-nas'>
        <div className='container' style={{ paddingTop: '100px' }}>
          <div>
            <div className='column is-10 is-offset-1' style={{ margin: '3%' }}>

              <PageContent className='content' content={content} />

            </div>
          </div>
        </div>
      </section>




      <br />
      <br />

      <div className='bottomUi' style={{ textAlign: 'center', marginBottom: '30px', marginLeft: '20px' }}>
        <Link className='button-green' style={{ fontSize: '15px' }} to='/offer/'> Discover our offer&nbsp;&nbsp; <img width="12px" style={{ verticalAlign: 'middle', marginRight: '5px' }} alt="arrow" src='/img/angle-right.svg' /> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{ fontSize: '15px' }} to='/portfolio/'> See our realizations&nbsp;&nbsp; <img width="12px" style={{ verticalAlign: 'middle', marginRight: '5px' }} alt="arrow" src='/img/angle-right.svg' /> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{ fontSize: '15px' }} to='/blog/'> Read the blog&nbsp;&nbsp; <img width="12px" style={{ verticalAlign: 'middle', marginRight: '5px' }} alt="arrow" src='/img/angle-right.svg' /> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{ fontSize: '15px' }} to='/contact/'> Contact us&nbsp;&nbsp; <img width="12px" style={{ verticalAlign: 'middle', marginRight: '5px' }} alt="arrow" src='/img/angle-right.svg' /> </Link>



      </div>

      <br />
      <br />
      <ModalCall />
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
