import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import AboutPageTemplate from '../components/AboutPageTemplate'
import Layout from '../components/Layout'
import config from '../../config'
import SE0 from '../components/SEO'

const AboutPage = (props) => {
  const { data: { markdownRemark: post } } = props

  for (let i=1;i<post.frontmatter.keywords.length;i++){
    if (post.frontmatter.keywords[i].charAt(0)!==' '){
    post.frontmatter.keywords[i] = ' ' + post.frontmatter.keywords[i];
    }
    //console.log(keywords[i]);
  }

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>
      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://artopen.co/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "About us",
        }]
      })}
      </script>
      </Helmet>
      <SE0
        title={post.frontmatter.title}
        meta_title={post.frontmatter.meta_title}
        meta_desc={post.frontmatter.meta_description}
        keywords={post.frontmatter.keywords}
        slug="/about-us/"
        cover="/img/o-nas.svg"
        siteTitleAlt={config.siteTitleAlt}
        userName={config.userName}
        siteTitle={config.siteTitle}
        siteUrl={config.siteUrl}
        siteFBAppID={config.siteFBAppID}
        userTwitter={config.userTwitter}
        pathPrefix={config.pathPrefix}
      />

      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta_title
        meta_description
        keywords
      }
    }
  }
`
